.navigation {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 1rem 0;
  list-style: none;
  font-family: $font-family-highlight;
  font-size: 0;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-xl;
  }

  &__item {
    flex: 1 1 auto;
    text-align: center;
    margin: 0.25rem;

    i {
      margin-left: auto;
      margin-right: auto;
      font-size: $font-size-lg;
    }

    @include media-breakpoint-up(sm) {
      margin: 0 0.5rem 0 0;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: inherit;
      }
    }
  }

  &__link {
    display: block;
    text-align: center;
    padding: 0.5rem;
    text-decoration: none;
    color: $color-white;
    background-color: $color-secondary;
    border: 0;
    border-radius: 0.8rem;

    &:hover,
    &:focus {
      background-color: $color-secondary-hover;
      color: $color-gray-400;
      text-decoration: none;
    }

    &--active {
      color: $color-white;
      background-color: $color-primary;

      &:hover,
      &:focus {
        background-color: $color-primary-hover;
      }
    }
  }
}
