$icon-list: calendar-days, envelope, facebook, instagram, link, location-dot, users;

.icon {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  margin-bottom: -0.125em;
  margin-right: 0.125em;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  opacity: 1;

  @each $icon in $icon-list {
    &--#{$icon} {
      background-color: currentColor;
      mask-image: url('../img/#{$icon}.svg');
    }
  }
}
