.legal {
  display: flex;

  @include media-breakpoint-up(sm) {
    float: right;
  }

  &__link {
    flex: 1 1 auto;
    padding: 0.25rem 0.5rem;
    border-right: 1px solid $color-gray-600;
    margin: 0;

    &:last-child {
      border: 0;
    }
  }
}
