.concert {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.8rem;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  background: $color-gray-700;
  box-shadow: $box-shadow-sm;
  position: relative;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  &:hover,
  &:hover &__date {
    box-shadow: $box-shadow-base;
  }

  &__info {
    flex: 4 1 80%;
  }

  &__title {
    font-size: 1.5rem;
    font-family: $font-family-highlight;
    text-transform: uppercase;
    margin: 0 0 6px;
    padding: 0 0 4px;
    border-bottom: 2px dotted $color-gray-600;
    hyphens: auto;

    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }
  }

  &__lineup {
    margin-bottom: 1rem;
  }

  &__footer {
    padding-top: 0.5rem;
    margin: 0.5rem 0 0;
    border-top: 2px dotted $color-gray-600;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
  &__date {
    flex: 1 1 20%;
    align-self: flex-start;
    text-align: center;
    padding: 0.25rem;
    margin: 0 2rem 1rem 0;
    border-bottom: 2px solid $color-primary;

    @include media-breakpoint-up(sm) {
      padding: 0.5rem;
      margin-bottom: 0;
      background-color: $color-primary;
      border-radius: 0.6rem;
      border: 0;

      &__top,
      &__bottom {
        display: block;
      }
      &__top {
        font-family: $font-family-highlight;
        padding-bottom: 0.25rem;
        font-size: 2.25rem;
      }
      &__bottom {
        padding-top: 0.45rem;
        border-top: 2px dotted darken($color-gray-700, 10%);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &--past {
    background: darken($color-gray-700, 10%);
    color: $color-gray-400;

    .band__name {
      color: $color-gray-400;
    }

    .concert__date {
      color: $color-gray-500;
      border-color: $color-gray-600;
      @include media-breakpoint-up(sm) {
        background-color: $color-gray-600;
      }
    }
  }

  &--highlight {
    @include media-breakpoint-up(sm) {
      padding-right: 4rem;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 1.5rem;
        background-image: url('../img/star.svg');
        background-color: $color-primary;
        background-position: 50% 65%;
        background-repeat: no-repeat;
        background-size: 60% auto;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.25);
        width: 2.5rem;
        height: 4rem;
        opacity: 0.75;
      }
    }
  }

  &--hidden {
    display: none;
  }
}

.band {
  font-size: 1.4rem;
  line-height: $line-height-sm;

  &:nth-child(n + 3) {
    font-size: 1.2rem;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0.25rem;
    font-size: 1.5rem;

    &:nth-child(n + 3) {
      font-size: 1.25rem;
    }
  }

  &__name {
    color: $color-white;
    font-family: $font-family-highlight;
    text-transform: uppercase;
  }

  &__details {
    display: block;
    font-size: 0.85rem;
    line-height: $line-height-sm;
    margin-bottom: 0.5rem;
    color: $color-gray-500;

    @include media-breakpoint-up(sm) {
      display: inline;
      margin: 0;
    }
  }
}

.concert-toggler {
  display: block;
  border: 0;
  width: 100%;
  border-radius: 0.8rem;
  padding: 1rem 1.25rem;
  margin: 3.5rem 0 1rem;
  background: $color-gray-700;
  box-shadow: $box-shadow-sm;
  position: relative;
  appearance: none;
  cursor: pointer;
  color: $color-white;

  &:hover {
    background-color: $color-primary-hover;
    box-shadow: $box-shadow-base;
  }

  &::before {
    content: '•••';
    background: $color-gray-800;
    border-radius: 0.8rem;
    color: $color-white;
    display: block;
    position: absolute;
    width: 100%;
    height: 1.5rem;
    top: -2.5rem;
    left: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    user-select: none;
    pointer-events: none;
  }
}
