*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden !important;
  background: $color-gray-900;
  color: $color-gray-300;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

.container {
  @include make-container();
  @include make-container-max-widths();
}

.section {
  margin: 0 0 3rem;
}

.footer {
  border-top: 2px solid $color-secondary;
  padding: 1rem 0;
}
