html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

input {
  overflow: visible;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.pull-right {
  float: right !important;
}

@font-face {
  font-display: swap;
  font-family: Patua One;
  font-style: normal;
  font-weight: 400;
  src: url("patua-one-v20-latin-regular.7ea8c455.woff2") format("woff2");
}

:root {
  --font-factor: .75;
}

@media (width >= 768px) {
  :root {
    --font-factor: 1;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0 0 .5rem;
  color: inherit;
  font-family: Patua One, sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

h1, .h1 {
  font-size: calc(2.5rem * var(--font-factor));
  border-bottom: 2px solid #17a2b8;
  margin-bottom: 1rem;
  padding-bottom: .25rem;
}

h2, .h2 {
  font-size: calc(2rem * var(--font-factor));
}

h3, .h3 {
  font-size: calc(1.75rem * var(--font-factor));
}

h4, .h4 {
  font-size: calc(1.5rem * var(--font-factor));
}

h5, .h5 {
  font-size: calc(1.25rem * var(--font-factor));
}

h6, .h6 {
  font-size: 1rem;
}

h1, .h1, h2, .h2 {
  font-family: Patua One, sans-serif;
  line-height: 1.2;
}

.lead {
  font-size: 1.25rem;
}

small, .small {
  font-size: .875rem;
}

hr {
  border: 0;
  border-top: 1px solid #adb5bd;
  margin: .5rem 0;
}

p {
  margin: 0 0 .5rem;
}

a {
  color: #17a2b8;
  text-decoration: none;
}

a:hover {
  color: #0f6674;
  text-decoration: underline;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #dee2e6;
  background: #212529;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  overflow-x: hidden !important;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

.section {
  margin: 0 0 3rem;
}

.footer {
  border-top: 2px solid #6c757d;
  padding: 1rem 0;
}

.concert {
  background: #495057;
  border-radius: .8rem;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 1rem #00000020;
}

@media (width >= 576px) {
  .concert {
    flex-direction: row;
  }
}

.concert:hover, .concert:hover .concert__date {
  box-shadow: 0 0 1.75rem #00000080;
}

.concert__info {
  flex: 4 80%;
}

.concert__title {
  text-transform: uppercase;
  -webkit-hyphens: auto;
  hyphens: auto;
  border-bottom: 2px dotted #6c757d;
  margin: 0 0 6px;
  padding: 0 0 4px;
  font-family: Patua One, sans-serif;
  font-size: 1.5rem;
}

@media (width >= 576px) {
  .concert__title {
    font-size: 1.8rem;
  }
}

.concert__lineup {
  margin-bottom: 1rem;
}

.concert__footer {
  border-top: 2px dotted #6c757d;
  margin: .5rem 0 0;
  padding-top: .5rem;
}

@media (width >= 992px) {
  .concert__footer {
    margin: 0;
  }
}

.concert__date {
  text-align: center;
  border-bottom: 2px solid #17a2b8;
  flex: 20%;
  align-self: flex-start;
  margin: 0 2rem 1rem 0;
  padding: .25rem;
}

@media (width >= 576px) {
  .concert__date {
    background-color: #17a2b8;
    border: 0;
    border-radius: .6rem;
    margin-bottom: 0;
    padding: .5rem;
  }

  .concert__date__top, .concert__date__bottom {
    display: block;
  }

  .concert__date__top {
    padding-bottom: .25rem;
    font-family: Patua One, sans-serif;
    font-size: 2.25rem;
  }

  .concert__date__bottom {
    text-overflow: ellipsis;
    border-top: 2px dotted #32373b;
    padding-top: .45rem;
    overflow: hidden;
  }
}

.concert--past {
  color: #ced4da;
  background: #32373b;
}

.concert--past .band__name {
  color: #ced4da;
}

.concert--past .concert__date {
  color: #adb5bd;
  border-color: #6c757d;
}

@media (width >= 576px) {
  .concert--past .concert__date {
    background-color: #6c757d;
  }

  .concert--highlight {
    padding-right: 4rem;
  }

  .concert--highlight:after {
    content: "";
    opacity: .75;
    background-color: #17a2b8;
    background-image: url("star.6adcb099.svg");
    background-position: 50% 65%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    width: 2.5rem;
    height: 4rem;
    position: absolute;
    top: 0;
    right: 1.5rem;
    box-shadow: 3px 3px 15px #00000040;
  }
}

.concert--hidden {
  display: none;
}

.band {
  font-size: 1.4rem;
  line-height: 1.2;
}

.band:nth-child(n+3) {
  font-size: 1.2rem;
}

@media (width >= 576px) {
  .band {
    margin-bottom: .25rem;
    font-size: 1.5rem;
  }

  .band:nth-child(n+3) {
    font-size: 1.25rem;
  }
}

.band__name {
  color: #fff;
  text-transform: uppercase;
  font-family: Patua One, sans-serif;
}

.band__details {
  color: #adb5bd;
  margin-bottom: .5rem;
  font-size: .85rem;
  line-height: 1.2;
  display: block;
}

@media (width >= 576px) {
  .band__details {
    margin: 0;
    display: inline;
  }
}

.concert-toggler {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  color: #fff;
  background: #495057;
  border: 0;
  border-radius: .8rem;
  width: 100%;
  margin: 3.5rem 0 1rem;
  padding: 1rem 1.25rem;
  display: block;
  position: relative;
  box-shadow: 0 0 1rem #00000020;
}

.concert-toggler:hover {
  background-color: #0f6674;
  box-shadow: 0 0 1.75rem #00000080;
}

.concert-toggler:before {
  content: "•••";
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background: #343a40;
  border-radius: .8rem;
  width: 100%;
  height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: block;
  position: absolute;
  top: -2.5rem;
  left: 0;
}

.header {
  margin: 0 0 1rem;
}

@media (width >= 576px) {
  .header {
    margin-bottom: 2rem;
  }
}

.header__logo {
  margin: 0 auto;
  padding: 1.5rem .5rem 0;
  transition: all .5s ease-in-out;
  display: block;
  transform: scale(.5)rotate(0);
}

@media (width >= 576px) {
  .header__logo {
    padding: 2.5rem 1rem 1rem;
  }
}

.header__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.initialized .header .header__logo {
  transform: scale(1)rotate(-3deg);
}

@media (width >= 576px) {
  .initialized .header .header__logo {
    transform: scale(1)rotate(-4deg);
  }

  .initialized .header .header__logo:hover {
    transition: all .125s ease-in-out;
    transform: scale(.98)rotate(-3.5deg);
  }

  .initialized .header .header__logo:hover .header__image {
    filter: drop-shadow(.25rem .35rem .75rem #000000a6);
  }
}

.legal {
  display: flex;
}

@media (width >= 576px) {
  .legal {
    float: right;
  }
}

.legal__link {
  border-right: 1px solid #6c757d;
  flex: auto;
  margin: 0;
  padding: .25rem .5rem;
}

.legal__link:last-child {
  border: 0;
}

.navigation {
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 0;
  font-family: Patua One, sans-serif;
  font-size: 0;
  list-style: none;
  display: flex;
}

@media (width >= 576px) {
  .navigation {
    font-size: 1.5rem;
  }
}

.navigation__item {
  text-align: center;
  flex: auto;
  margin: .25rem;
}

.navigation__item i {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
}

@media (width >= 576px) {
  .navigation__item {
    margin: 0 .5rem 0 0;
  }

  .navigation__item:last-child {
    margin-right: 0;
  }

  .navigation__item i {
    font-size: inherit;
  }
}

.navigation__link {
  text-align: center;
  color: #fff;
  background-color: #6c757d;
  border: 0;
  border-radius: .8rem;
  padding: .5rem;
  text-decoration: none;
  display: block;
}

.navigation__link:hover, .navigation__link:focus {
  color: #ced4da;
  background-color: #494f54;
  text-decoration: none;
}

.navigation__link--active {
  color: #fff;
  background-color: #17a2b8;
}

.navigation__link--active:hover, .navigation__link--active:focus {
  background-color: #0f6674;
}

.icon {
  font-size: inherit;
  opacity: 1;
  width: 1em;
  height: 1em;
  margin-bottom: -.125em;
  margin-right: .125em;
  display: inline-block;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.icon--calendar-days {
  background-color: currentColor;
  -webkit-mask-image: url("calendar-days.fdb905f7.svg");
  mask-image: url("calendar-days.fdb905f7.svg");
}

.icon--envelope {
  background-color: currentColor;
  -webkit-mask-image: url("envelope.0dad06c1.svg");
  mask-image: url("envelope.0dad06c1.svg");
}

.icon--facebook {
  background-color: currentColor;
  -webkit-mask-image: url("facebook.75fdf455.svg");
  mask-image: url("facebook.75fdf455.svg");
}

.icon--instagram {
  background-color: currentColor;
  -webkit-mask-image: url("instagram.9ab50df8.svg");
  mask-image: url("instagram.9ab50df8.svg");
}

.icon--link {
  background-color: currentColor;
  -webkit-mask-image: url("link.0d183544.svg");
  mask-image: url("link.0d183544.svg");
}

.icon--location-dot {
  background-color: currentColor;
  -webkit-mask-image: url("location-dot.1518bb4a.svg");
  mask-image: url("location-dot.1518bb4a.svg");
}

.icon--users {
  background-color: currentColor;
  -webkit-mask-image: url("users.f6ca3b52.svg");
  mask-image: url("users.f6ca3b52.svg");
}
/*# sourceMappingURL=index.e654b4de.css.map */
