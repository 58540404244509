@font-face {
  font-display: swap;
  font-family: 'Patua One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/patua-one-v20-latin-regular.woff2') format('woff2');
}
:root {
  --font-factor: 0.75;

  @include media-breakpoint-up(md) {
    --font-factor: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0 0 0.5rem;
  font-family: $font-family-highlight;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: inherit;
}

h1,
.h1 {
  font-size: $h1-font-size;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid $color-primary;
  margin-bottom: 1rem;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
}
h6,
.h6 {
  font-size: $h6-font-size;
}
h1,
.h1,
h2,
.h2 {
  font-family: $font-family-highlight;
  line-height: $line-height-sm;
}

.lead {
  font-size: $font-size-lg;
}

small,
.small {
  font-size: $font-size-sm;
}

hr {
  margin: 0.5rem 0;
  border: 0;
  border-top: 1px solid $color-gray-500;
}

p {
  margin: 0 0 0.5rem;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-primary-hover;
    text-decoration: underline;
  }
}
