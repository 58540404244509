$selector: '.header';

#{$selector} {
  margin: 0 0 1rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }

  &__logo {
    display: block;
    padding: 1.5rem 0.5rem 0;
    margin: 0 auto;
    transform: scale(0.5) rotate(0);
    transition: $transition-base;

    @include media-breakpoint-up(sm) {
      padding: 2.5rem 1rem 1rem;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}

.initialized .header .header__logo {
  transform: scale(1) rotate(-3deg);

  @include media-breakpoint-up(sm) {
    transform: scale(1) rotate(-4deg);
    &:hover {
      transition: all 0.125s ease-in-out;
      transform: scale(0.98) rotate(-3.5deg);

      #{$selector}__image {
        filter: drop-shadow(0.25rem 0.35rem 0.75rem rgb(0, 0, 0, 0.65));
      }
    }
  }
}
